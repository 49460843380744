let sourceDataURL = 'https://yb4h60cdb6.execute-api.eu-west-2.amazonaws.com/PROD/measure'
// 'https://my-json-server.typicode.com/PluginHunter/MyFirstBlog/measures'
let appVersion = '0.9.1'
let weightData = [];
let fatData = [];

let dataArray = []

let weightGraphConfig = {
  type: "line",
  data: {
    labels: [],
    datasets: [{
      data: weightData, // Set initially to empty data
      label: "Weight",
      borderColor: "green",
      fill: false
    }
    ]
  },
  options: {
    scales: {
      xAxes: [{
        type: "time",
        distribution: "linear"
      }],
      title: {
        display: false
      }
    }
  }
};
let fatGraphConfig = {
  type: "line",
  data: {
    labels: [],
    datasets: [
      {
        data: fatData, // Set initially to empty data
        label: "Fat",
        borderColor: "red",
        fill: false
      }
    ]
  },
  options: {
    scales: {
      xAxes: [{
        type: "time",
        distribution: "linear"
      }],
      title: {
        display: false
      }
    }
  }
};

let appVersionField = document.getElementById('appversion');
appVersionField.textContent = appVersion;


let weightCanvas = document.getElementById('weight')
let weightChart = new Chart(weightCanvas, weightGraphConfig);

let fatCanvas = document.getElementById('fat')
let fatChart = new Chart(fatCanvas, fatGraphConfig);

async function updateData() {

  const response = await fetch(sourceDataURL);

  const data = await response.json();

  data.map(row => {
    dataArray.push(row);
    let date = moment(row.measureDate);
    let weight = row.weight;
    let fat = row.fat;
    let weightMeasure = { x: date, y: weight }
    let fatMeasure = { x: date, y: row.fat }


    if (weight > 0) { weightData.push(weightMeasure) }
    if (fat > 0) { fatData.push(fatMeasure) }
  });
  weightChart.update();
  fatChart.update();


  // update text fields

  let startDate = document.getElementById("start_date");
  let initialWeight = document.getElementById("initial_weight");
  let currentDate = document.getElementById("current_date");
  let currentWeight = document.getElementById("current_weight");
  let totalLost = document.getElementById("total_lost");
  let averageLost = document.getElementById("average_lost");
  let lastWeekLost = document.getElementById("last_week_lost");

  let dataArrayrraySize = dataArray.length - 1;
  startDate.textContent = moment(dataArray[0].measureDate).format('DD-MM-YYYY');
  initialWeight.textContent = dataArray[0].weight;

  currentDate.textContent = moment(dataArray[dataArrayrraySize].measureDate).format('DD-MM-YYYY');
  currentWeight.textContent = dataArray[dataArrayrraySize].weight;

  let totalLostValue = (dataArray[0].weight - dataArray[dataArrayrraySize].weight)
  totalLost.textContent = totalLostValue.toFixed(1);

  let weeksInTotal = moment(dataArray[dataArrayrraySize].measureDate).diff(moment(dataArray[0].measureDate), 'week')
  let averageLostValue = (totalLostValue / weeksInTotal)
  // console.log(typeof(averageLostValue));


  averageLost.textContent = averageLostValue.toFixed(2);
  lastWeekLost.textContent = "not calculated yet"

}

updateData();